<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2-no-top"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.code')"
                :placeholder="$t('labels.code')"
                name="code"
                sort-name="code"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :options="typeOptions"
                :label="$t('labels.type_supply')"
                :placeholder="$t('labels.type_supply')"
                name="type"
                sort-name="type"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :options="classifyOptions"
                :label="$t('labels.classify')"
                :placeholder="$t('labels.classify')"
                name="classify"
                sort-name="classify"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.name')"
                :placeholder="$t('labels.name')"
                name="name"
                sort-name="name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.note')"
                :placeholder="$t('labels.note')"
                name="note"
                sort-name="note"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.phone_number')"
                :placeholder="$t('labels.phone_number')"
                name="phone"
                sort-name="phone"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectCity
                class="c-input-xs input-filter"
                name="id_city"
                :label="$t('labels.city')"
                @onChange="onFilterChange"
              />
            </th>
            <th role="columnheader">
              <SelectCounty
                class="c-input-xs input-filter"
                name="id_county"
                :id-city="filters.id_city"
                :label="$t('labels.district')"
                @onChange="onFilterChange"
              />
            </th>
            <th role="columnheader">
              <SelectWard
                class="c-input-xs input-filter"
                name="id_ward"
                :id-county="filters.id_county"
                :id-city="filters.id_city"
                :label="$t('labels.ward')"
                @onChange="onFilterChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.address')"
                :placeholder="$t('labels.address')"
                name="address"
                sort-name="address"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center" style="width: 80px">
              {{ $t("labels.create_time_1") }}
            </th>
            <th class="text-center" style="width: 80px">
              {{ $t("labels.creator_name") }}
            </th>
            <th class="text-center" style="width: 100px">
              <v-btn
                small
                color="primary"
                @click="addItem"
                :disabled="isDisabledBtnAdd"
              >
                {{ $t("labels.add") }}
              </v-btn>
            </th>
            <!-- <th class="text-center" style="width: 80px;">{{ $t('labels.code') }}</th>
                        <th class="text-center" style="width: 120px;">{{ $t('labels.classify') }}</th>
                        <th class="text-center">{{ $t('labels.name') }}</th>
                        <th class="text-center">{{ $t('labels.note') }}</th>
                        <th class="text-center" style="width: 120px;">{{ $t('labels.phone_number') }}</th>
                        <th class="text-center" style="width: 120px;">{{ $t('labels.city') }}</th>
                        <th class="text-center" style="width: 120px;">{{ $t('labels.district') }}</th>
                        <th class="text-center" style="width: 120px;">{{ $t('labels.ward') }}</th>
                        <th class="text-center" style="width: 120px;">{{ $t('labels.address') }}</th> -->
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, key) in items"
            :key="`sl_${key}_${item.id}`"
            class="text-center"
          >
            <td>
              <span v-if="!item.editing || item.id">
                <span
                  v-if="item.classify === 2"
                  class="primary--text cursor-pointer text-decoration-underline"
                  @click="showUseDialog(item)"
                >
                  {{ item.code }}
                </span>
                <span v-else>{{ item.code }}</span>
              </span>
              <v-text-field
                v-else
                v-model="item.code"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                :label="$t('labels.code')"
              ></v-text-field>
            </td>
            <td>
              <span v-if="!item.editing">
                {{ $t(`labels.supplier_type_${item.type}`) }}
              </span>
              <v-autocomplete
                v-else
                v-model.number="item.type"
                :disabled="!item.editing"
                :items="typeOptions"
                class="c-input-small input-disabled-bold"
                :label="$t('labels.type_supply')"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </td>
            <td>
              <span v-if="!item.editing">
                {{ $t(`labels.supplier_classify_${item.classify}`) }}
              </span>
              <v-autocomplete
                v-else
                v-model.number="item.classify"
                :disabled="!item.editing"
                :items="classifyOptions"
                class="c-input-small input-disabled-bold"
                :label="$t('labels.classify')"
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </td>
            <td>
              <span v-if="!item.editing">{{ item.name }}</span>
              <v-text-field
                v-else
                v-model="item.name"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                :label="$t('labels.name')"
              ></v-text-field>
            </td>
            <td>
              <span v-if="!item.editing">{{ item.note }}</span>
              <v-text-field
                v-else
                v-model="item.note"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                :label="$t('labels.note')"
              ></v-text-field>
            </td>
            <td>
              <span v-if="!item.editing">{{ item.phone }}</span>
              <v-text-field
                v-else
                v-model="item.phone"
                :disabled="!item.editing"
                :label="$t('labels.phone_number')"
                class="c-input-small"
                outlined
                dense
                hide-details
              ></v-text-field>
            </td>
            <td>
              <span v-if="!item.editing" class="text-capitalize">
                {{ item.city_name }}
              </span>
              <SelectCity
                v-else
                class="c-input-xs"
                :id-item="item.id"
                :id-city="item.id_city"
                :label="$t('labels.city')"
                name="id_city"
                @onChange="onItemChange"
              />
            </td>
            <td>
              <span v-if="!item.editing" class="text-capitalize">
                {{ item.county_name }}
              </span>
              <SelectCounty
                v-else
                class="c-input-xs"
                :id-item="item.id"
                :id-city="item.id_city"
                :id-county="item.id_county"
                :label="$t('labels.district')"
                name="id_county"
                @onChange="onItemChange"
              />
            </td>
            <td>
              <span v-if="!item.editing" class="text-capitalize">
                {{ item.ward_name }}
              </span>
              <SelectWard
                v-else
                class="c-input-xs"
                :id-item="item.id"
                :id-city="item.id_city"
                :id-county="item.id_county"
                :id-ward="item.id_ward"
                :label="$t('labels.ward')"
                name="id_ward"
                @onChange="onItemChange"
              />
            </td>
            <td>
              <span v-if="!item.editing">{{ item.address }}</span>
              <v-text-field
                v-else
                v-model="item.address"
                :disabled="!item.editing"
                class="c-input-small"
                outlined
                dense
                hide-details
                :label="$t('labels.address')"
              ></v-text-field>
            </td>
            <td>
              {{ item.created_at ? formatDateTime(item.created_at) : "" }}
            </td>
            <td>{{ item.identity_name }}</td>
            <td class="py-1">
              <template v-if="!item.editing">
                <v-btn
                  x-small
                  color="warning"
                  @click="toggleEditing(item, key, true)"
                  >{{ $t("labels.edit") }}</v-btn
                >
              </template>
              <template v-else>
                <v-btn
                  class="mr-1"
                  x-small
                  color="success"
                  @click="saveItem(item)"
                >
                  {{ $t("labels.save") }}
                </v-btn>
                <v-btn
                  x-small
                  color="error"
                  @click="toggleEditing(item, key, false)"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <div class="pt-3">
      <v-pagination
        v-model="page"
        :length="totalPage"
        :total-visible="6"
      ></v-pagination>
    </div>

    <v-dialog v-model="useDialog" persistent max-width="820px">
      <WpSupplierDialog
        v-if="useDialog"
        @cancel="hideUseDialog"
        :supplier="supplier"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";
import { SUPPLIER_TYPE_OPTIONS, SUPPLIER_CLASSIFY_OPTIONS } from "@/libs/const";

export default {
  name: "Supplier",
  components: {
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    SelectCity: () => import("@/components/common/SelectCity"),
    SelectCounty: () => import("@/components/common/SelectCounty"),
    SelectWard: () => import("@/components/common/SelectWard"),
    WpSupplierDialog: () => import("@/components/supply/WpSupplierDialog"),
  },
  props: {},
  data: () => ({
    items: [],
    totalPage: 1,
    page: 1,
    useDialog: false,
    isLoading: false,
    filters: {},
  }),
  computed: {
    isDisabledBtnAdd() {
      const checkItem = [...this.items].find((item) => !item.id);
      return !!checkItem;
    },
    typeOptions() {
      return [...SUPPLIER_TYPE_OPTIONS].map((t) => ({
        text: this.$t(`labels.supplier_type_${t}`),
        value: t,
      }));
    },
    classifyOptions() {
      return [...SUPPLIER_CLASSIFY_OPTIONS].map((t) => ({
        text: this.$t(`labels.supplier_classify_${t}`),
        value: t,
      }));
    },
  },

  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },

    closeDialog() {
      this.$emit("close", true);
    },

    showUseDialog(item) {
      this.supplier = { ...item };
      this.useDialog = true;
    },
    hideUseDialog() {
      this.supplier = {};
      this.useDialog = false;
    },

    onItemChange(val) {
      const items = [...this.items];
      let item = [...items].find((i) => i.id === val.id);
      if (item) {
        const itemIndex = [...items].findIndex((i) => i.id === val.id);
        item = { ...item, [val.name]: val.value };
        items[itemIndex] = item;
        this.items = [...items];
      }
    },

    getList: debounce(function () {
      httpClient
        .post("/supplier-list", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
        });
    }, 500),

    async saveItem(item) {
      if (!item.code || item.code.length > 3) {
        this.$vToastify.warning(this.$t("messages.emty_max_3_characters"));
        return false;
      }
      if (!item.name || (!item.type && item.type != 0)) {
        this.$vToastify.warning(this.$t("messages.emty_name_classification"));
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/save-supplier", item);
        this.isLoading = false;
        this.getList();
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    addItem() {
      if (this.isDisabledBtnAdd) {
        return false;
      }
      this.items.unshift({
        id: null,
        name: null,
        type: null,
        note: null,
        editing: true,
      });
    },
  },
};
</script>

<style scoped></style>
